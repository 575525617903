import * as S from 'apps/cliniko/style'
import Button from 'components/button.legacy'
import * as _ from 'modules/util'

const BannerButton = ({ color = 'dark', href, text, ...props }) => (
  <Button
    css={buttonStyles({ color })}
    key={text}
    href={href}
    reload={_.when(href, true)}
    {...props}>
    {text}
  </Button>
)

const buttonStyles = ({ color }) => ({
  background: _.cond(
    [color === 'dark', S.legacy.colors.secondaryDark],
    [color === 'danger', S.legacy.colors.error],
    [color === 'warning', S.legacy.colors.warning]
  ),
  color: color === 'dark' ? 'white' : 'inherit',
  borderColor: _.cond(
    [color === 'dark', S.legacy.colors.secondaryDark],
    [color === 'danger', S.legacy.colors.errorBorder],
    [color === 'warning', S.legacy.colors.warningLine]
  ),

  '+ button, + a': {
    marginLeft: S.rem(4),
  },

  '&:hover, &[data-focus-visible-added]': {
    background: _.cond(
      [color === 'dark', S.legacy.colors.secondaryDarker],
      [color === 'danger', S.legacy.colors.errorShade],
      [color === 'warning', S.legacy.colors.warningShade]
    ),
    color: _.when(color === 'dark', 'white'),
    textDecoration: 'none',
  },
})

export default BannerButton
