import * as S from 'apps/cliniko/style'
import Prose from 'components/prose.legacy'
import Transition from 'components/transition'
import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'
import BannerButton from './banner-button'

const Banner = ({ actions, animate = true, children, className, when = true }) => {
  const definedActions = L.collect(L.elems, actions)

  return (
    <Transition
      className={className}
      css={containerStyles}
      enter={_.when(animate, { opacity: 1, transform: 'translateY(0)' })}
      from={_.when(animate, { opacity: 0, transform: 'translateY(-100%)' })}
      leave={_.when(animate, { opacity: 0, transform: 'translateY(-100%)' })}
      when={when}>
      <div css={innerContainerStyles}>
        <Prose css={messageStyles}>{children}</Prose>
        {_.when(
          definedActions.length,
          <div css={S.legacy.breakpoint.belowLarge(S.center)}>
            <div css={actionsStyles}>{R.map(BannerButton, definedActions)}</div>
          </div>
        )}
      </div>
    </Transition>
  )
}

const containerStyles = {
  ...S.legacy.space({ pt: 1.25, pb: 1.2, px: 1.5 }),
  background: S.legacy.colors.warning,
}

const innerContainerStyles = {
  ...S.legacy.flexWrap(),
  justifyContent: 'space-between',
}

const messageStyles = {
  lineHeight: S.legacy.unit(2),

  'a[data-focus-visible-added]': {
    color: 'inherit',
    outlineColor: 'white',
  },

  [S.legacy.media.belowLarge]: {
    width: '100%',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}

const actionsStyles = {
  ...S.legacy.flexWrap(),
  justifyContent: 'center',

  [S.legacy.media.belowLarge]: {
    textAlign: 'center',
  },
}

export default Banner
